import React from 'react';
import { Link } from 'gatsby';

import {
    Card,
    Container,
    Divider,
    Embed,
    Feed,
    Header,
    Image,
    List,
    Menu,
    Segment,
} from 'semantic-ui-react';
import Layout from '../../components/layout';
import Helmet from '../../components/Helmet';
import * as DefaultTemplate from '../../components/LandingPages/DefaultTemplate';
import WhitePaperImage1 from '../../images/whitepapers/credit-counseling/the-ultimate-consumer-credit-counseling-guide-0.png';
import WhitePaperImage2 from '../../images/whitepapers/credit-counseling/u-s-homeownership-rate-1.png';
import WhitePaperImage3 from '../../images/whitepapers/credit-counseling/reasons-millennials-with-student-loans-have-to-wait-to-buy-a-house-2017-2.png';
import WhitePaperImage4 from '../../images/whitepapers/credit-counseling/u-s-retirement-readiness-index-score-3.png';
import WhitePaperImage5 from '../../images/whitepapers/credit-counseling/saver-vs-spender-what-are-they-saving-for-2017-4.png';
import WhitePaperImage6 from '../../images/whitepapers/credit-counseling/u-s-personal-bankruptcy-rate-2017-5.png';
import VideoPlaceHolder from '../../images/whitepapers/credit-counseling/video-credit-counseling.png';
import OptionsTemplate from '../../components/ContentPages/OptionsTemplate';
import AdvertiserDisclosurePopUp from '../../components/Disclosures/AdvertiserPopUp';

const offers = [
    {
        logo: '/assets/images/logos/logo-debt-advisor-with-icon.png',
        company: 'Debt Advisor',
        link: 'https://www.ac934intrk.com/3J67C/37GQ4B/?sub3=60456',
        rating: 5,
        details: [{
            header: 'Better Business Bureau',
            content: 'Not rated',
        }, {
            header: 'Years in Business',
            content: '6+',
        }, {
            header: 'Free Consultation?',
            content: 'Yes',
        }],
        mainValueProp: 'Reduce your debt with Debt Advisor',
        benefits: [
            {
                item: 'No-cost consultations',
            },
            {
                item: 'Cut your credit card payments by up to 50%',
            },
            {
                item: 'Receive funds as soon as the next business day!',
            },
            {
                item: 'Click here for official site, terms, and details',
                link: true,
            },
        ],
        review: ['With over 1 million debt relief requests yearly, Debt Advisor is one of several owned and operated brands connecting consumers seeking debt relief with financial service providers nationwide. Financial problems arise and we help consumers f ind a solution for their debt relief needs.'],
    },
];

const featuredContent = [
    {
        slug: '/credit-counseling/family-budget-services-review/',
        image: 'fbsi-header.png',
        title: 'Family Budget Services Review',
    },
    {
        slug: '/credit-counseling/ecredit-advisor-review/',
        image: 'eCreditAdvisor_Home-buying.png',
        title: 'eCredit Advisor Review',
    },
    {
        slug: '/credit-card-debt/',
        image: 'credit-card-debt.jpg',
        title: 'How to Deal With Credit Card Debt',
    },
];

const relatedContent = [
    {
        slug: '/debt-relief/',
        image: 'what-debt-relief-means.jpg',
        title: 'Debt Relief: What It Means',
    },
    {
        slug: '/how-to-get-out-of-debt/',
        image: 'how-to-get-out-of-debt.jpg',
        title: 'How to Get Out of Debt',
    },
    {
        slug: '/debt-throughout-history/',
        image: 'debt-through-history.jpg',
        title: 'An Exploration of Debt Throughout History',
    },
];


const CreditCounselingPage = () => (
    <Layout showInformationDisclosure>
        <Helmet
            title="Credit Counseling"
            description="Credit counseling can help you pay off debt and regain good spending habits. Find qualified credit counselors, debt reduction strategies, and more right here."
        />
        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Hero>
                <>
                    <Segment attached>
                        <Container fluid className="hero hero__credit-counseling" textAlign="center">
                            <Container text>
                                <AdvertiserDisclosurePopUp />
                                <Header as="h1" size="large">Credit Counseling</Header>
                                <Header as="h2" size="medium">Learn debt reduction strategies & more</Header>
                            </Container>
                        </Container>
                    </Segment>
                    <Menu pointing attached>
                        <Menu.Item href="/">Home</Menu.Item>
                        <Menu.Item href="/loans/">Debt Consolidation</Menu.Item>
                        <Menu.Item href="/debt-settlement/">Debt Settlement</Menu.Item>
                        <Menu.Item href="/credit-counseling/" active>Credit Counseling</Menu.Item>
                    </Menu>
                </>
            </DefaultTemplate.Hero>

            <DefaultTemplate.Featured>
                <OptionsTemplate offers={offers} />
                <Divider hidden />
                <Container textAlign="center">
                    <Header size="large" textAlign="center">
                        Learn About Debt
                    </Header>
                    <div className="content--featured">
                        <Feed>
                            {featuredContent.map((item, idx) => (
                                <Feed.Event
                                    key={idx.toString() + item.slug}
                                    as="a"
                                    href={item.slug}
                                    image={`/media/resize/200x150/${item.image}`}
                                    content={item.title}
                                />
                            ))}

                        </Feed>
                    </div>
                    <div className="content--featured__desktop">
                        <Card.Group stackable itemsPerRow={3}>
                            {featuredContent.map((item, idx) => (
                                <Card key={idx.toString() + item.slug} as={Link} to={item.slug}>
                                    <Image src={`/media/resize/400x300/${item.image}`} wrapped ui={false} />
                                    <Card.Content>
                                        <Card.Header>
                                            {item.title}
                                        </Card.Header>
                                    </Card.Content>
                                </Card>
                            ))}
                        </Card.Group>
                    </div>
                </Container>
            </DefaultTemplate.Featured>

            <DefaultTemplate.Body>
                <Divider hidden className="half-size" />
                <Header as="h3">The Ultimate Consumer Credit Counseling Guide</Header>
                <p><Image src={WhitePaperImage1} alt="The Ultimate Consumer Credit Counseling Guide" /></p>
                <p>Americans are in record-breaking debt.</p>
                <p>
                    Low financial literacy, easy access to
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&sub1=credit-counseling&sub2=credit-cards"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        credit cards
                    </a>
                    {' '}
                    and a snowballing
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&sub1=credit-counseling&sub2=student-loan"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        student loan
                    </a>
                    {' '}
                    crisis are putting consumers in over their heads with no end in sight.
                </p>
                <p>Multiple monthly payments with high-interest rates fuel stress and anxiety, making it hard to not just give up.</p>
                <p>But giving up leads to defaulted loans, court judgments, and bankruptcy, which can leave your credit score in tatters and render you ineligible for a mortgage or car loan.</p>
                <p>With the highest outstanding credit card debt ever and millions of defaulted student loans, U.S. debt is taking a huge toll on the economy.</p>
                <p>The Federal Reserve Bank of New York reported that home ownership by people in their late 20s has declined by 35% as a result of student loan debt and that in turn suppresses economic activity.</p>
                <p><Image src={WhitePaperImage2} alt="U S Homeownership Rate" /></p>
                <p>The health of the American economy depends on the financial health of its citizens.</p>
                <p>Consumer credit counseling can help you get out of debt.</p>
                <p>
                    Therefore, with the proper financial education and tools, you can repair your credit and access funds for the
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&sub1=credit-counseling&sub2=important-purchases-in-life"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        important purchases in life
                    </a>
                    , like a home or car, and plan for the future with retirement savings.
                </p>
                <p>If you have already tried to reduce your debt on your own and failed, a consumer credit counselor can help you find the right solution to your financial problems and support you until you are debt-free.</p>
                <p>Let consumer credit counseling relieve the stress of owing more than you can afford and put you in control of your finances.</p>
                <Divider hidden className="half-size" />
                <Header as="h3">
                    How it Works
                    <Header.Subheader>Get consumer credit counseling for customized financial guidance</Header.Subheader>
                </Header>
                <p>
                    <strong>Confidential one-on-one sessions with a financial expert.</strong>
                    {' '}
                    Consumer credit counseling is a service offered by non-profit agencies or companies that provide guidance on financial matters.
                </p>
                <p>Whether it&rsquo;s too much debt, budgeting problems, or lack of financial knowledge, consumer credit counselors partner with clients to find the right solutions to their specific money problems.</p>
                <p>Private consumer credit counseling sessions are completely confidential and the counselors are non-judgmental, so no matter how deeply you&rsquo;re in debt or how little you understand money management, you can get the advice you need with zero shame.</p>
                <p>The American Institute of Certified Public Accountants found that about one-quarter (28%) of the country reports they stress every single day about financial decisions because of their debt.</p>
                <p>When over 1 out of 4 us are freaking out daily over money, it is time for us all to get financially healthy.</p>
                <Header as="h3">Many need consumer credit counseling now more than ever</Header>
                <p>Americans owe a record-breaking $1.021 trillion in outstanding credit card debt, according to the Federal Reserve.</p>
                <p>This may come as a surprise to those of us that thought it was free money only to discover we now have a wallet full of maxed out credit cards and are barely capable of making minimum payments.</p>
                <p>
                    <strong>Almost five million Americans have defaulted on their student loans.</strong>
                    {' '}
                    That number, according to the Wall Street Journal, represents 22% of all those with outstanding
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&sub1=credit-counseling&sub2=student-loan"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        student loan
                    </a>
                    {' '}
                    debt.
                </p>
                <p>The consequences of defaulted student loans are severe and include garnished wages, accelerated interest payments, and inability to qualify for a mortgage or car loan.</p>
                <p><Image src={WhitePaperImage3} alt="Reasons Millennials With Student Loans Have to Wait to Buy a House (2017)" /></p>
                <p>
                    <strong>Millennials struggle with overwhelming debt.</strong>
                    {' '}
                    The generation facing the student loan crisis says its debt is too much. And the numbers show that they are right.
                </p>
                <p>A study by National Endowment for Financial Education (NEFE) revealed that two-thirds of millennials have long-term debts and over 30% have long-term debt with two or more creditors.</p>
                <Header as="h3">Consumer credit counseling can keep you out of court</Header>
                <p>
                    <strong>You can avoid serious actions like court judgments and bankruptcy.</strong>
                    {' '}
                    Before defaulting on your student loan or allowing outstanding credit card bills to go into collections, let a credit counselor devise a repayment plan that can reduce your debt in affordable ways.
                </p>
                <p>
                    <strong>Consumer credit counseling tackles almost any kind of debt.</strong>
                    {' '}
                    Whether it&rsquo;s student loans, mortgages, car loans,
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=credit-counseling&sub2=personal-loans"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        personal loans
                    </a>
                    , medical bills, housing costs, or credit card debt, a credit counselor can provide a workable solution to pay off your debt.
                </p>
                <p>
                    <strong>Consumer credit counseling takes the fear out of communicating with creditors.</strong>
                    {' '}
                    The best part is that the counselors negotiate a repayment plan with your creditors directly.
                </p>
                <p>It may seem ridiculous, but the struggle is real. Fear of money, or Chrematophobia, includes anxiety and/or avoidance in dealing with financial issues.</p>
                <Header as="h3">Consumer credit counseling can lower your credit score, but won&rsquo;t keep you from getting a student loan</Header>
                <p>People also worry that consumer credit counseling will hurt their FICO credit score.</p>
                <p>While credit counseling classes and private sessions will not affect your credit score, the debt management plan (DMP) put together by your credit counselor may use programs that will lower your credit score.</p>
                <p>
                    <strong>Debt consolidation and settlement programs will show up on your report.</strong>
                    {' '}
                    However, your credit score will quickly bounce back after you&rsquo;re debt-free, and considering how much longer it takes to rebuild credit after a court judgment or bankruptcy, it is the best choice in the long run.
                </p>
                <p>
                    <strong>Repayment plans won&rsquo;t affect your ability to get a student loan.</strong>
                    {' '}
                    Government student loan approval is not based on credit reports or scores.
                </p>
                <Header as="h3">Consumer credit counseling won&rsquo;t break the bank</Header>
                <p>
                    <strong>Most initial consumer credit counseling sessions are free.</strong>
                    {' '}
                    During your first session, a credit counselor will offer the best options based on your financial situation.
                </p>
                <p>
                    <strong>Costs depend on state regulations and vary by agency.</strong>
                    {' '}
                    Some states regulate consumer credit counseling agencies and cap fees on services.
                </p>
                <p>
                    <strong>Non-profit consumer credit counseling is free or really cheap.</strong>
                    {' '}
                    Member agencies of the National Foundation for Credit Counseling (NFCC) offer most services for free or at very low costs to clients — typically around $20.
                </p>
                <p>
                    <strong>Consumer credit counseling for-profit companies charge much higher fees.</strong>
                    {' '}
                    The
                    {' '}
                    <a href="https://fcaa.org/">Financial Counseling Association of America (FCAA)</a>
                    {' '}
                    members charge a DMP enrollment fee of about $75 and a monthly fee around $50, but these are estimates as fee structures differ by company.
                </p>
                <p>However, the FCAA does require members to offer fee waivers for those who cannot afford their services.</p>
                <Divider hidden className="half-size" />
                <Header as="h3">
                    What They Can Do For You
                    <Header.Subheader>Debt and financial illiteracy go hand-in-hand</Header.Subheader>
                </Header>
                <p>
                    <strong>Gen-Xers and millennials have trouble grasping money management concepts.</strong>
                    {' '}
                    The NEFE showed that a mere 24% of millennials showed basic financial literacy, and worse, only 8% scored high in understanding money matters.
                </p>
                <p>When asked how they will finance their retirement, according to Allianz Life, 46% of Gen-Xers replied they will &ldquo;figure it out when I get there.&rdquo; That&rsquo;s not smart.</p>
                <p><Image src={WhitePaperImage4} alt="US Retirement Readiness Index score" /></p>
                <p>
                    <strong>You can improve your financial literacy.</strong>
                    {' '}
                    Consumer credit counseling agencies and companies provide financial education classes and resources that can fill the gaps in your money management knowledge.
                </p>
                <Header as="h3">Consumer credit counselors construct customized Debt Management Plans</Header>
                <p>
                    <strong>Management Plans could use consolidation, settlement, or a mixture of both and more.</strong>
                    {' '}
                    Your credit counselor uses accounting, debt, and spending details in order to create your DMP. Therefore, pull all financial documentation together before your first session.
                </p>
                <p>Your DMP could involve a cocktail of different programs like debt consolidation, debt settlement, tools to improve budgeting, and educational materials specific to where your financial knowledge is lacking.</p>
                <p>DMPs cannot be used to pay off secured debt (backed by collateral like a house or car).</p>
                <p>After you are debt-free, your credit counselor can help you with household budgeting and help you establish good credit.</p>
                <p>The goal is to get you out of debt, keep you out of debt, and give you the skills to achieve financial empowerment.</p>
                <p>
                    <strong>Know the details of the DMP before you enroll.</strong>
                    {' '}
                    Credit counselors should provide a breakdown of all fees and the length of time it will take to pay off your debt before you sign up.
                </p>
                <p>Usually, non-profit consumer credit counseling agencies require you to refrain from applying for any new credit or use any existing credit for the length of the DMP — around four years.</p>
                <p>
                    <strong>DMPs take discipline.</strong>
                    {' '}
                    If your DMP does not put restrictions on applying for new debt or when you have paid off all your debt, resist the temptation to apply for new credit. Be very careful not to run up your debt again.
                </p>
                <p>
                    There are
                    {' '}
                    <Link to="/stick-to-your-budget/">strategies that can help you stick to your DMP or budget</Link>
                    {' '}
                    so that you stay out of debt for good.
                </p>
                <p><Image src={WhitePaperImage5} alt="Saver vs Spender: What Are They Saving For? (2017)" /></p>
                <Header as="h3">Consolidating debt takes the pain out of monthly payments</Header>
                <p>
                    <strong>The first step in a debt consolidation plan is to reduce the total owed.</strong>
                    {' '}
                    By negotiating with your creditors, your credit counselor will seek lower interest rates and ask that any fees be waived so your monthly payments are affordable.
                </p>
                <p>
                    <strong>A debt reduction program that gives you a single monthly payment.</strong>
                    {' '}
                    Debt consolidation rolls all your debt into one total that you pay in monthly installments to the consumer credit counseling agency. Your consumer credit counselor then distributes payments to your creditors.
                </p>
                <p>
                    <strong>
                        A debt consolidation program is not the same as a
                        {' '}
                        <Link to="/loans/">debt consolidation loan</Link>
                        .
                    </strong>
                    {' '}
                    When a DMP uses a debt consolidation program, it is strictly a debt reduction process that does not take on any new debt.
                </p>
                <p>
                    A debt consolidation
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=credit-counseling&sub2=loan"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        loan
                    </a>
                    {' '}
                    is money you borrow from a lender, like a bank or credit union, at a lower interest rate than existing debt, which you then make monthly payments on to repay the loan.
                </p>
                <p>
                    <strong>You do not have to close all your accounts at the end of the program.</strong>
                    {' '}
                    Your consumer credit counselor can give you advice on which credit to maintain and which accounts to close.
                </p>
                <p>
                    You can keep and improve your ability to access funds while learning how to repair your credit score and, ultimately, qualify for
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&sub1=credit-counseling&sub2=loans"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        loans
                    </a>
                    {' '}
                    and mortgages.
                </p>
                <Header as="h3">Debt settlement pays off debt in one lump sum</Header>
                <p>
                    <strong>A quick &rsquo;n&rsquo; dirty way to pay less and stay out of bankruptcy.</strong>
                    {' '}
                    Debt settlement
                    {' '}
                    <Link to="/debt-settlement/">is a program reserved for those who owe massive amounts to creditors</Link>
                    {' '}
                    and cannot realistically afford the monthly payments of any other repayment plan.
                </p>
                <p>After you enroll in a debt settlement program, your credit counselor will negotiate lower totals with all your creditors by offering to wipe out the entire balance in one payment.</p>
                <p>Creditors may agree because they prefer to collect something rather than nothing, which is what they will get if you declare bankruptcy.</p>
                <p>
                    <strong>Debt settlement is a last resort before bankruptcy.</strong>
                    {' '}
                    Your credit score really takes a beating when you settle your debt and it stays on your credit report for seven years.
                </p>
                <p>Bankruptcy stays on your report for 10 years and a good consumer credit counselor will advise you on how to repair your credit after the program ends.</p>
                <p>Your credit counselor may ask you to stop paying monthly minimums to creditors for the length of the program (generally 2–4 years) and send them a single, monthly payment you can afford.</p>
                <p>The money accumulates in an escrow account until there is enough to pay off your debt.</p>
                <p>It&rsquo;s fast and effective but negatively affects your payment history, thus the lower credit score.</p>
                <p>
                    <strong>Debt settlement fees are pricey.</strong>
                    {' '}
                    Make sure your consumer credit counselor thoroughly covers the fee structure before you sign up for a debt settlement program.
                </p>
                <p>
                    <strong>Any credit counselor who asks for upfront fees is shady.</strong>
                    {' '}
                    Legitimate agencies and companies include fees in your monthly payment or add them to the total amount of debt you are paying off.
                </p>
                <p>
                    <strong>Fee assessment varies by agency or company.</strong>
                    {' '}
                    Your consumer credit counseling agency or company will determine debt settlement fees in one of three ways:
                </p>
                <List bulleted>
                    <List.Item>A percentage of the total debt</List.Item>
                    <List.Item>A percentage of the debt reduction, or</List.Item>
                    <List.Item>A fixed amount added to your monthly payments.</List.Item>
                </List>
                <p>
                    <strong>The usual range for percentage of total debt fees is 13–20%.</strong>
                    {' '}
                    If you were $30,000 in the hole, you would pay $3,900–$6,000 in fees.
                </p>
                <p>
                    <strong>Percentage of debt reduction fees can be as high as 35%.</strong>
                    {' '}
                    Again, let&rsquo;s say your total debt was $30,000, but your consumer credit counselor negotiated a settlement that cut that in half to $15,000. If debt settlement fee was 27%, it would be $4,050.
                </p>
                <p>
                    <strong>Debt settlement fees paid monthly are about $20–$90.</strong>
                    {' '}
                    These fees are automatically added to the amount you pay every month.
                </p>
                <Header as="h3">Consumer credit counseling provides bankruptcy alternatives</Header>
                <p>
                    <strong>Sometimes bankruptcy is the best option.</strong>
                    {' '}
                    Losing your job or astronomical medical bills are two of many reasons bankruptcy could be the right financial decision.
                </p>
                <p>
                    <strong>You must finish consumer credit counseling before you can go bankrupt.</strong>
                    {' '}
                    The federal government requires approved pre-bankruptcy credit counseling and pre-discharge debtor education to legally declare bankruptcy.
                </p>
                <p><Image src={WhitePaperImage6} alt="US Personal Bankruptcy Rate (2017)" /></p>
                <p>
                    <strong>Student loan debt cannot be discharged by bankruptcy.</strong>
                    {' '}
                    Your student loans are one of
                    {' '}
                    <Link to="/bankruptcy/">several debts that won&rsquo;t go away if you declare bankruptcy</Link>
                    , but there are other ways to tackle
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&sub1=credit-counseling&sub2=student-loan"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        student loan
                    </a>
                    {' '}
                    debt.
                </p>
                <Header as="h3">Consumer credit counseling can help reduce and pay off student loan debt</Header>
                <p>
                    <strong>Consumer credit counselors approach student loan debt differently.</strong>
                    {' '}
                    Unlike other consumer debt, government and private student loans have different rules that a credit counselor can help you understand.
                </p>
                <p>
                    <strong>Repayment and consolidation programs are available.</strong>
                    {' '}
                    A consumer credit counselor can detail these programs and help you decide which one is right for you.
                </p>
                <p>
                    <strong>Credit counselors can identify possible debt reduction opportunities.</strong>
                    {' '}
                    Your credit counselor can show you options for loan cancellation and forgiveness, as well as ways to reduce interest.
                </p>
                <Header as="h3">Don&rsquo;t let debt-stress take a toll on your health or wealth</Header>
                <p>With consumer credit counseling, you have a partner to help you pay off your debt and repair your credit.</p>
                <p>
                    The right one will teach you how to stay debt-free, stick to your budget, and
                    {' '}
                    <a
                        href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&sub1=credit-counseling&sub2=begin-saving"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        begin saving
                    </a>
                    {' '}
                    for retirement.
                </p>
                <p>It will empower you to make good financial decisions about buying a home, a car, or investing your money.</p>
                <p>You do not have to face your financial problems alone.</p>
                <p>Have you ever used a credit counselor?</p>
                <p>How wonderful (or horrible) was the experience?</p>
                <p>We&rsquo;d love to know. Leave us a comment below.</p>
                <Divider hidden />
            </DefaultTemplate.Body>

            <DefaultTemplate.PreSidebar>
                <Header size="medium">
                    Watch: 5 Things You Didn&rsquo;t Know About Credit Counseling
                </Header>
                <Embed id="HA_lb1VSA_I" source="youtube" placeholder={VideoPlaceHolder} />
                <Divider hidden />
            </DefaultTemplate.PreSidebar>

            <DefaultTemplate.PostSidebar>
                <Header size="medium">
                    Related Content
                </Header>
                <Feed>
                    {relatedContent.map((item, idx) => (
                        <Feed.Event
                            key={item.slug + idx.toString()}
                            as="a"
                            href={item.slug}
                            image={`/media/resize/200x150/${item.image}`}
                            content={item.title}
                        />
                    ))}
                </Feed>
            </DefaultTemplate.PostSidebar>

        </DefaultTemplate.Wrapper>
    </Layout>
);

export default CreditCounselingPage;
